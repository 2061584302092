export default {
  changeLang: 'Change language',
  back: 'Back',
  header: {
    profile: 'My profile',
    homeLink: 'Homepage',
    authentication: 'Authentication',
    myServicesLink: 'My procedures',
    onlineServicesLink: 'Online services',
    appointmentsLink: 'My appointments',
    documentsLink: 'My documents-holder',
    logout: 'Logout',
    navigationLabel: 'Navigation',
    publicServiceIndividuals: 'Public Services for Individuals',
    publicServiceBusinesses: 'Public Services for Businesses',
    individuals: 'Individuals',
    businesses: 'Businesses',
    searchPlaceholder: 'Search for an online service',
    documentSearchPlaceholder: 'Search for a document',
    popup: {
      mainText:
        'To access the {userTypePopup}  area, you will be logged out. <br/> You will then be able to log in using your {userTypePopup} usernames (or create an account). \n',
      logout: 'Log out?\n',
      wouldYouLikeToLogout: ' Would you like to log out?\n',
    },
    accessMonGuichet: 'Access MonGuichet.mc {userTypePopup}',
    partnersLabel: 'See all linked websites',
    partnersActionLabel: 'OuvertureSitesLiés',
    partnersCloseButtonActionLabel: 'Fermeture',
    partnersLink:
      'https://en.gouv.mc/Portail-du-Gouvernement/Policy-Practice/Related-sites',
    infosLabel:
      'All of the useful services and information for users in the Principality on the official websites of the Prince’s Government of Monaco',
    certifiedWebsiteLabel: 'Official website of the Principality of Monaco',
    userAccountLabel: 'Account: ',
    metanav: {
      governmentSiteHref: 'https://en.gouv.mc/Portail-du-Gouvernement',
      governmentSiteLabel: 'The Prince’s Government of Monaco website',
      monGuichetHref: 'https://monguichet.mc/',
      monGuichetLabel: 'The portal, where you can access online services',
      myPublicServiceHref: 'https://monservicepublic.gouv.mc/en',
      myPublicServiceLabel: 'All services and informations for individuals',
      myCompanyServiceHref: 'https://monentreprise.gouv.mc/en',
      myCompanyServiceLabel: 'All services and informations for businesses',
    },
  },
  footer: {
    legal: 'Legal notice',
    cookies: 'Cookies',
    contact: 'Contact',
    tou: 'TOU',
    copyright:
      'All rights reserved - Monaco {prodYear} - {currentYear} - {appVersion}',
    socialNetwork: 'Follow us on social networks',
    partnersLabel:
      'Follow this link to find all partner sites linked to the Government',
    partnersLink: 'https://www.google.fr/',
    governmentLink: 'https://en.gouv.mc/',
    infosLabel:
      "Find all information and services on the websites of the Prince's Government of Monaco",
  },
  login: {
    login: 'Log in',
  },
  signup: {
    pageTitle: 'Create an account',
    titleBusinesses: 'Create a Business account',
    backToAccountChoice: 'Back',
    titleIndividuals: 'Create an Individual account',
    address: 'My address',
    identifyWithMconnect: 'Identify yourself with MConnect',
    loginWithMconnect: 'Log in with MConnect',
    chooseUsernames: 'Choose your usernames',
    usernamePlaceholder: 'Ex : johndoe06',
    alreadyHaveAnAccount: 'I already have an account',
    haveVisa: 'I am the holder of a residence permit',
    checkYourMailbox: 'Check your mailbox',
    finalize:
      'To complete your registration, please click on the  <b>activation link</b> that was sent to your email address.',
    finish: 'Finish',
    pleaseAcceptTerms:
      'To use this service, please accept the <a href="{page}" target="blank" class="text-interaction">Terms of Use</a>.',
    iAcceptTerms:
      'I have read and accept the <a class="text-interaction" href="{termUrl}" target="blank">Terms of Use</a> and that my personal data will be processed in the context of MonGuichet.mc.',
    socialReason: 'Registered name',
    NISNumber: 'NIS (Monaco Statistics Business ID) number',
    CARNumber: 'CAR (Independent Pensions Fund) number ',
    RCINumber: 'RCI (Trade and Industry Registry) number ',
    socialReasonTooltip:
      'Name of the business established when it was started and which is listed in the Trade Registry',
    NISNumberTooltip:
      'Statistical Identification Number allocated by Monaco Statistics when a business is started.\n',
    NISNumberTooltip2:
      'The NIS comprises four digits, one letter then five digits.',
    CARNumberTooltip:
      'Employer number supplied by the Monaco Social Security Funds',
    RCINumberTooltip:
      'Registration number in the Trade and Industry Registry.\n',
    RCINumberTooltip2:
      'The RCI is made up of two digits indicating the year, the letter “P” or “S”, and five digits representing the registration number.',
    optionalInfos:
      'This information is optional, you can complete it later in your profile.',
    socialReasonPlaceholder: 'Ex : MyBusiness',
    NISNumberPlaceholder: 'Ex : 1234A56789',
    CARNumberPlaceholder: 'Ex : CAR',
    RCINumberPlaceholder: 'Ex : 09P12345',
  },
  home: {
    subtitle:
      'Find the procedures that can be carried out online and track your requests',
    mainMessage:
      "The <span class='text-red_individual'>online services</span> portal of the Prince's Government and Monaco City Hall",
    mainMessagePro:
      "The <span class='text-brand_blue_secondary'>online services</span> portal of the Prince's Government and Monaco City Hall",
    followOngoingProcedures: 'Follow my procedures',
    followMyAppointments: 'My appointments',
    findOnlineService: 'Find an online service',
    fastAccesses: 'Fast accesses',
    myActivity: 'My activity',
    onlineServices: 'Online services',
    myFavourites: 'My favourites',
    survey: {
      title: 'Share your views!',
      textIndividuals: `Help us to improve your personal area on MonGuichet.mc.`,
      textProfessionals: `Help us to improve the MonGuichet.mc personal area for business users.`,
      surveyButtonLabel: 'Complete the survey',
    },
    cards: {
      myProcedures: 'My procedures',
      myAppointments: 'My appointments',
      documentsHolder: 'My documents-holder',
    },
    mconnectCard: {
      description:
        'Thanks to your Monegasque <span class="text-red_individual">Digital Identity</span> :',
      followAppointments: 'View appointments',
      secureConnection: 'Log on safely',
      simplifyMyProcedures: 'Simplify your online services',
      documentsHolder: 'Benefit from a documents-holder',
      askCivilStatusCertificates:
        'Request a civil registration or nationality certificate',
      useMConnect: 'Use',
    },
    mconnect:
      'From now on, <strong class="font-semibold">you must use MConnect</strong> to access your Individual account.',
    individuals: 'individuals',
    professional: 'professional',
    revocation: 'Fill in your digital identity information.',
    revocationActionLabel: 'Fill in my information',
  },
  actions: {
    modify: 'Modify',
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    yes: 'Yes',
    no: 'No',
    previous: 'Previous',
    next: 'Next',
    select: 'Select',
    selectDocumentType: 'Select a type',
    search: 'Search',
    addDocument: 'Add a document',
    filter: 'Filter',
    deleteFilters: 'Reset the filters',
    keepDocument: 'Keep the document',
    keepDocuments: 'Keep the documents',
    delete: 'Delete',
    deleteSelection: 'Delete | Delete the documents',
    mobileDeleteSelection: 'Delete {count} document | Delete {count} documents',
    download: 'Download',
    downloadSelection: 'Download | Download the documents',
    mobileDownloadSelection:
      'Download {count} document | Download {count} documents',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    apply: 'Apply',
    terminate: 'Terminate',
    retry: 'Retry',
  },
  errors: {
    usernameContainsWhitespaces: 'The username must not contain any spaces.',
    usernameDoesNotRespectPosixStandard:
      'Only alphanumeric characters, periods and dashes can be used',
    addressMustBeValid: 'The address is incorrect',
    usernameFirstCharacterMustBeALetter: 'The first character must be a letter',
    mandatoryField: 'This field is mandatory',
    invalidEmail:
      'This email address is incorrect or you do not have any activated account',
    incorrectEmail: 'This email address is incorrect',
    emailsMustMatch: 'The email address and its confirmation must be identical',
    incorrectPassword: 'The password is incorrect',
    passwordsMustMatch: 'The password and its confirmation must be identical',
    mustNotContainANumber: 'This field must not contain a number',
    minLength: 'This field must contain at least {limit} characters.',
    minPasswordLength: 'The password must contain at least {limit} characters.',
    passwordShouldContainLowercaseLetter:
      'The password must contain at least one lowercase letter.',
    passwordShouldContainUppercaseLetter:
      'The password must contain at least one uppercase letter.',
    passwordShouldContainANumber:
      'The password must contain at least one number',
    passwordShouldContainASpecialCharacter:
      'The password must contain at least one special character',
    maxLength: 'This field must contain at max {limit} characters.',
    password: {
      mustContain: 'The password must contain: ',
      length: '9 characters min',
      uppercase: 'at least 1 capital letter',
      lowercase: 'at least 1 lower case letter',
      number: 'at least 1 number',
      special: "at least 1 special character among () ? _'; : {'@'} # & * + =",
    },
    usernameAlreadyTaken:
      'Existing login. If you have already created an account on the Public Service website, log in with your usual username.',
    expectedDateFormat: 'The expected format is DD/MM/YYYY',
    dateMustBeBeforeToday: 'The date must be earlier than the current date',
    somethingWentWrong: 'Something went wrong...',
    emailSameAsOld: 'This e-mail address is the same as before.',
    wrongCredential:
      'Incorrect Username and/or Password. Reminder: If you have already used MConnect to access your Individual account, please login with MConnect only.',
    numericIdIsOnlyForIndividuals:
      'The digital identity is only available for individuals, we are not able to link your digital identity to a Business account.',
    captchaError: 'The captcha could not be verified',
    invalidFileSize: 'The selected file size is greater than {limit} Mo',
    invalidFileExtension: 'The selected file extension is invalid',
  },
  forgotIdentifier: {
    title: 'Forgotten your username?',
    hint: 'To retrieve your username, please enter the email address you used when you created your account.',
    confirmationTitle: 'Check your mailbox',
    sent: 'Your username has been sent to you by email.',
  },
  profile: {
    profile: 'Your profile',
    account: 'My account',
    usernames: 'My usernames',
    username: 'Username',
    password: 'Password',
    confirmPassword: 'Re-enter password',
    aboutMe: 'About me',
    homeAddress: 'My home address',
    companyAddress: 'Your company address',
    myPersonalInfo: 'My personal information',
    myInfo: 'My information',
    companyInfo: 'Business information',
    email: 'Email Address',
    modifyEmail: 'Modify the e-mail address',
    modifyPassword: 'Modify the password',
    identifier: 'Username',
    technicalIdentifier: 'Account',
    emailConfirmation: 'Re-enter email address',
    contactEmail: 'Contact email address ',
    login: 'Log in',
    identity: 'My identity',
    contactInfos: 'My contact informations',
    address: 'My address',
    company: 'My company',
    personalInfos: {
      surname: 'Name of use',
      MconnectSurname: 'Birth name',
      firstName: 'First Name',
      secondName: 'Second name',
      thirdName: 'Third name',
      nationality: 'Nationality',
      civility: {
        civility: 'Title',
        madam: 'Madam',
        sir: 'Sir',
        modify: 'Modify my civility',
      },
      residencePermitHolder: 'Residence permit holder',
    },
    aboutMyCompany: {
      aboutMyCompany: 'Business information',
      socialReason: 'Registered name',
      nisNumber: 'NIS (Monaco Statistics Business ID) number',
      carNumber: 'CAR (Independent Pensions Fund) number ',
      rciNumber: 'RCI (Trade and Industry Registry) number ',
    },
    addressInfos: {
      streetAddress: 'Residence address',
      companyStreetAddress: 'Company address',
      additionalAddress: 'Address complement',
      secondAdditionalAddress: 'Second address complement',
      additionnalAddressPlaceholder:
        'Company, building, appart., floor, PO box, ...',
      postalCode: 'Postal Code',
      city: 'City',
      country: 'Country',
      additionalAddressLabel: 'Additional address details',
    },
    changeEmail: {
      title: 'Change your email address',
      enterPassword: 'First, please enter your current password',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Password',
      enterEmail: 'Enter your new email address',
      emailLabel: 'New email address',
      confirmEmailLabel: 'Re-enter new email address',
      success: 'Your email has been changed successfully',
      confirmationSentence: 'A confirmation e-mail has been sent to you.',
      backToProfile: 'Return to my profile',
    },
    confirmEmail: {
      title: 'Check your email address',
      text: 'To activate your new email address, </br> please click on the activation link that was sent to the email address you provided.',
    },
    unsubscribe: 'Unsubscribe',
    unsubscribePopup: {
      body: 'If you wish to unsubscribe, you can send a message by clicking here:  <a class="text-interaction cursor-pointer" href="https://contacts.gouv.mc/en/contact-monguichet" target="blank">https://contacts.gouv.mc/en/contact-monguichet</a> indicating your login and your contact email address.',
      title: 'Unsubscribe?',
      footer:
        'If you unsubscribe, you will no longer be able to log in with this account, and you will be unsubscribed from all the online services to which you have subscribed.',
    },
    digitalIdentity: {
      verifiedBy: 'Verified by',
      migrateToTitle: 'Migrate to the digital identity',
      migrateToLabel:
        'Que vous soyez monegasque ou résident, passez à l’identité numérique pour bénéficier de services inédits et réduire vos nombres d’identifiants et de mots de passe !',
      useMConnect: 'Use MConnect to log in to MonGuichet',
      getMConnect: 'Get the digital identity',
      title: 'My Digital Identity',
      name: 'Birth name',
      nameUse: 'Name of use',
      firstName: 'First name(s)',
      nationality: 'Nationality',
      youAre: 'You are',
      birth: 'Date of birth',
      cityOfBirth: 'City of birth',
      countryOfBirth: 'Country of birth',
      registrationAuthority: 'Registration Authority',
      resident: 'Resident',
      nonResident: 'Non Resident',
      monagasque: 'Monegasque',
      policeDepartment: 'Police Department',
      cityHall: 'Monaco City Hall',
      digitalServicesDepartment: 'Digital Services Department',
      information:
        'Is this information wrong? Contact Digital Identity Support by clicking here !',
    },
    digitalIdentityRegister: {
      title: 'Finalise creating your account',
      subtitle: 'Please fill in your address to finalise creating your account',
      addressLabel: 'Address',
      addressPlaceholder: 'Ex : 4, allée des roses',
      additionnalAddressPlaceholder: 'Additional address details',
      zipCodeLabel: 'Postal Code',
      zipCodePlaceholder: 'Ex : 98000',
      cityLabel: 'City',
      cityPlaceholder: 'Ex : Monaco',
      countryLabel: 'Country',
      select: 'Select',
      button: 'Create my account',
    },
    fromProcedure: {
      backToProcedure: 'Return to my procedure',
      warning:
        'You have commenced an online procedure. You can change the information you have entered below, and can then continue with this procedure.',
    },
    useMconnectBox: {
      title: 'Switch to digital identity',
      content:
        '<span class="font-semibold">Monegasque citizens and residents</span>: use new services with fewer usernames and passwords!',
      button: 'Use MConnect',
      linkLabel: 'Obtain a digital identity',
      link: 'https://mconnect.gouv.mc/decouvrir',
    },
    useMconnectPage: {
      route: '/use-mconnect',
      title: 'Use MConnect to log on to MonGuichet',
      alertMessage:
        'You will <strong>link your MonGuichet.mc account</strong> to your Digital Identity.<br/>You will then <strong>use MConnect to log in</string> to online services',
      youMust: 'To use MConnect, you must:',
      step1: '1. Activate your Monegasque Digital Identity',
      info1: 'How to obtain and activate your digital identity',
      step2: '2. Install the MConnect mobile app on your smarphone',
      info2: 'How to install MConnect Mobile',
      connectBtn: 'Log on using MConnect',
      installLink: 'https://mconnect.gouv.mc/utiliser/installer-sur-mobile',
    },
    revocation: {
      notCompleted: {
        title: 'Informations to be filled in',
        announcement:
          'Some information needed to secure your account is missing',
        subtitle:
          '<span class="font-semibold">Fill in your digital identity informations</span> so that :',
        explanation1:
          'You can be notified regarding renewal of the use of your digital identity',
        explanation2:
          'In the event that your card is lost or stolen, your digital identity can be blocked',
        actionLabel: 'Fill in my information',
        knowMore: 'Learn more at mconnect.gouv.mc',
      },
      completed: {
        title: 'Completed information',
        announcement: 'Your account information is up to date',
        subtitle: 'You can now:',
        explanation1: 'Update your contact details if they change',
        explanation2:
          'In the event that your card is lost or stolen your digital identity can be blocked',
        actionLabel: 'Access the service',
        knowMore: 'Learn more at mconnect.gouv.mc',
      },
    },
  },
  services: {
    title: 'My procedures',
    followMyServicesTitle: 'See all my procedures',
    subtitle: 'You can find your realised procedures here',
    updated: 'Mise à jour',
    accessTo: 'Go to',
    update: 'Update',
    categories: 'Filters',
    request: 'request | requests',
    beingProcessed: 'In process',
    beingProcessedFullLabel: 'Being processed',
    waitingForUserRequest: 'Awaiting',
    waitingForUserRequestFullLabel: 'Awaiting action',
    treated: 'Treated',
    refused: 'Refused',
    completedRequest: 'Terminated',
    delete: 'Delete',
    showResults: 'Show results',
    informations: {
      title: 'Were you unable to trace your application online?',
      paperApplication: 'You have made an application on paper:',
      paperApplicationExplanation:
        ' We do not yet propose the tracking of the applications which have not been made online.',
      onlineApplication: 'You have made an application online:',
      onlineApplicationExplanation:
        'We have not yet linked all the procedures to MonGuichet.mc.',
      onlineApplicationTracking:
        'You can track it directly in the relevant Online Service.',
      missingOnlineApplication: 'Has your request disappeared?',
      missingOnlineApplicationExplanation:
        'Your request may have been deleted if it had reached its maximum retention period.',
    },
    announcementLabel:
      'You have an application that requires your attention. Please provide additional information so that they can be processed. | You have applications that require your attention. Please provide additional information so that they can be processed.',
    noServices: {
      title: 'No pending online procedures',
      description:
        'Only the procedures you have realised <span class="font-semibold">online</span> will be displayed <span class="font-semibold">here</span>.',
      action: 'Find an online procedure',
    },
  },
  onlineProcedures: {
    title: 'Online procedures',
    subtitle:
      'Here, you can find the procedures for individuals that you can carry out online',
    searchText: 'Look for an online procedure',
    categories: 'Filters:',
    results: 'result | results',
    tabs: {
      explore: 'Explore',
      myFavourites: 'My favourites',
    },
    noFavourites: {
      title: 'No favourites:',
      add: 'To add a favourite service:',
      item1: 'Click on the Explore tab',
      item2: 'Find the service',
      item3: 'Click on "..."',
      item4: 'Click on "Add to favourites"',
    },
    card: {
      consult: 'Understanding the process',
      access: 'Go to the online procedure',
      frenchAvailability: 'Only available in french',
      englandCode: 'EN',
      franceCode: 'FR',
      mconnectOnly: 'Service only available with ',
      mconnectOnlyFull: 'Use only MConnect to access this service',
      cityHallService: "Monaco City Hall's procedure",
      addToFavourites: 'Add to favourites',
      removeFromFavourites: 'Delete the favourite',
    },
    navigation: {
      title: "Didn't find what you were looking for?",
      servicePublicIndividuals: 'MonServicePublic',
      servicePublicCompany: 'MonEntreprise',
      notOnlineYet:
        'Your procedure may not be available on line yet. <br/>Find the procedure to be followed on {siteName}.gouv.mc website.',
      publicServiceButtonLabel: 'Go to {siteName}',
      scrollTop: 'Return to the top of the page',
    },
    noResults: {
      checkSpelling:
        'Check the spelling of the keywords and try changing some of the keywords (Ex : "car" instead of "cars").',
      notAvailable:
        'Your procedure may not be available on line yet. Find the procedure to be followed on {siteName}.gouv.mc website.',
    },
    availableServicesButtonLabel: 'Access to the procedures catalog',
    availableLanguages: 'Some online services are not available in English',
    deleteFavourite: {
      title: 'Delete a favourite',
      warning: 'Are you sure you want to delete this favourite ?',
      keep: 'Cancel',
      delete: 'Delete',
    },
    mconnectOnly: {
      title: 'Service accessible via MConnect',
      warning: 'To access this service, you must connect via MConnect.',
      description1:
        'You logged on to MonGuichet.mc with your username and password',
      description2:
        'If you already have a Monegasque digital identity, you can now use MConnect to log on to MonGuichet and access this service.',
      description3:
        'If you do not have a Monegasque digital identity, click on "obtain a digital identity".',
      useMyNumericIdentity: 'Use my digital identity',
      getANumericIdentity: 'Obtain a digital identity',
      cancel: 'Cancel',
    },
  },

  appointments: {
    title: 'My appointments',
    subtitle: 'You can find your booked appointments here',
    next: 'Next appointment',
    bookAnAppointment: 'Book an appointment',
    subheader: {
      incoming: 'Incoming',
      passed: 'Passed',
    },
    followingCard: {
      incoming: 'Incoming appointments',
      passed: 'Passed appointments',
      seeAll: 'See all my appointments',
    },
    description: {
      incoming: 'incoming appointments :',
      noneIncoming: 'No incoming appointments',
      passed: 'passed appointments :',
      nonePassed: 'No passed appointments',
    },
    noAppointments: {
      incomingTitle: 'No incoming appointments',
      passedTitle: 'No passed appointments',
      description:
        'Only appointments you have booked <span class="font-semibold">online</span> will be displayed <span class="font-semibold">here</span>',
    },
    notFound: {
      title: 'Can’t find your appointment details ?',
      explanation1:
        'Appointments made online with the Residency Section of the Police Department, the Employment Direction, the Driver and Vehicle Licensing Office and Monaco City Hall now appear here. Not all appointment services have yet been connected to MonGuichet.mc.',
      explanation2:
        'To manage your appointment, refer directly to the confirmation email or SMS you received.',
      explanation3:
        'Your appointments are displayed in your online account <span class="font-semibold">for one month</span> after the appointment date, before being automatically deleted.',
    },
    dropdown: {
      headerLabel: 'Make an appointment',
      bodyContent:
        'You can book your appointments directly online with some government departments:',
    },
    modifyAppointment: 'Modify',
    vehiclePlateNumber: 'Plate Number :',
    addAppointment: 'Add to my agenda',
    reason: 'Reason :',
    with: 'With :',
    place: 'Place :',
    statuses: {
      pending: 'Awaiting action',
      scheduled: 'Scheduled',
      cancelled: 'Cancelled',
      declined: 'Refused',
      noShow: 'No show',
      completed: 'Completed',
      inProgress: 'Being processed',
    },
  },

  docHolder: {
    noDocument: {
      title: 'No document',
      description: 'Add documents from your computer or mobile phone.',
      explanationTitle: 'Use the documents-holder to:',
      explanationFirstStep:
        '<b>Add</b> documents often used for applications (<b>proof of address, proof of identity, etc.</b>) from MonGuichet or an online service',
      explanationSecondStep:
        '<b>Keep</b> all of your documents in one place in your personal area on MonGuichet.mc',
      explanationThirdStep:
        '<b>Re-use</b> the documents for all of your requests, <b>from any device</b>',
    },
    documentsDisplay: {
      count: 'selected document | selected documents',
      description:
        'You are responsible for the retention period of your documents. They will be stored while your MonGuichet.mc account remains active and your consent to the documents-holder feature is valid. You can use the documents stored here in your online services.',
      add: 'Added the:',
      sizeLabel: 'Size:',
      sizeUnit: 'Mo',
      format: 'Format:',
      type: 'Justificative type:',
      endOfValidity: 'End of validity:',
      file: 'Original file:',
      docInfos: 'Document’s Informations',
      select: 'Select a document to know more about it',
      filters: {
        filterBy: 'Filter by',
        byJustificativeTypeLabel: 'Filter by Justificative type',
        allType: 'All Justificative types',
        byAdditionDate: 'Sort by addition date',
        antechronologicalOrder: 'From latest to oldest',
        chronologicalOrder: 'From oldest to latest',
      },
      shouldDisplayMultipleSelection:
        'Activate the selection of multiple documents',
      activated: 'Activated',
      deactivated: 'Deactivated',
      noResults: 'No results',
    },
    addDocument: {
      title: 'Add a document',
      fileLabel: 'File',
      firstCondition: 'The file should not exceed {size} Mo.',
      secondCondition: 'Accepted formats: {types}',
      documentName: "Document's name",
      documentNamePlaceholder: 'ex : my_file_2023',
      documentType: 'Justificative type',
      endOfValidity: 'End of validity',
      endOfValidityDateFormat: 'DD/MM/YYYY',
      loadingLabel: 'Loading of your document ...',
      successLabel: 'The loading has been successful !',
      failureLabel: 'The loading failed !',
    },
    termsOfUse: {
      title: 'Data protection policy',
      subtitle: 'Using your MonGuichet documents-holder:',
      content1:
        'Subject to your consent (tick the box below), you can submit and store documents and authorise their recovery via an online service.',
      content2:
        'These documents may contain personal data. They are recorded and stored in your documents-holder on your MonGuichet.mc account by the Digital Services Department of the State of Monaco as data controller to facilitate your use of the online services.',
      content3:
        'You have the following rights: the right to access your data, the right to withdraw your consent at any time, and the right to request that inaccurate, incomplete, or inaccurate data be amended or deleted.',
      content4:
        'All information relating to the processing of your personal data is available in the <a href="{termsOfUseLink}" target="_blank" class="text-interaction underline">Terms Of Use</a>.',
      legal:
        'I consent to my documents being processed by the documents-holder service<span class="text-red">*</span>',
      actionConsent: 'Accept',
      actionDeny: 'Refuse',
    },
    modifyDocument: {
      title: 'Modify a document',
    },
    deleteDocument: {
      title: 'Delete a document',
      warning: 'Do you confirm the deletion of this document ?',
      description: 'It will not appear anymore in your documents-holder',
      loadingLabel: 'Deletion of your document ...',
      successLabel: 'The deletion has been successful !',
      failureLabel: 'The deletion failed !',
    },
    massDeleteDocument: {
      title: 'Delete the selected documents',
      warning: 'Do you confirm the deletion of these documents ?',
      description: 'They will not appear anymore in your documents-holder',
      loadingLabel: 'Deletion of your documents ...',
    },
    documentDetails: {
      title: "Document's details",
    },
    deleteDocHolder: {
      action: 'Delete my documents-holder',
      confirmation: 'Are you sure you want to delete your documents-holder?',
      warning:
        'Your consent for the documents-holder and the stored files will be automatically deleted',
      keep: 'Keep my documents-holder',
      delete: 'Delete',
      loadingLabel: 'Deletion of your documents-holder ...',
      successLabel:
        'The deletion of your documents-holder has been successful !',
      failureLabel: 'The deletion of your documents-holder failed !',
    },
    announcement: {
      warningDeactivation:
        'Your consent to use the documents holder will expire on: {date}.<br/>If you wish to continue using your documents holder, please renew your consent.',
      warningDeletion:
        'Without your consent, your documents will automatically be deleted on {date}.<br/>If you wish to retain the use of your documents holder, please renew your consent.',
      action: 'Renew my consent',
    },
  },
  head: {
    businesses: 'Businesses',
    individuals: 'Individuals',
    connecting: 'Connecting',
    register: 'Create an account',
    forgotIdentifier: 'Forgot Username',
    error: 'Error',
  },
  apiGichuni: {
    title: 'API specification',
  },
  porteDoc: {
    title: 'Porte doc test',
  },
  contact: {
    title: 'Contact',
    link: 'https://contacts.gouv.mc/en/contact-monguichet',
  },
  register: {
    emailAlreadyExistsText:
      'The contact email address {email} is already associated with an existing account',
    explanationFirst: `We have identified that the contact email address <b>{email}</b> is already associated with one or more accounts.`,
    explanationSecond:
      'To see your previous services, please log in using your existing account.',
    connect: 'Log in',
    findIdentifier: 'Find your username',
    ignore: 'Ignore, continue to create account',
  },
  locales: {
    en: {
      text: 'EN',
      option: 'English',
      value: 'en',
    },
    fr: {
      text: 'FR',
      option: 'Français',
      value: 'fr',
    },
  },
}
