export default {
  changeLang: 'Changement de langue',
  back: 'Retour',
  header: {
    profile: 'Mon profil',
    homeLink: 'Accueil',
    authentication: 'Authentification',
    myServicesLink: 'Mes démarches',
    onlineServicesLink: 'Services en ligne',
    appointmentsLink: 'Mes rendez-vous',
    documentsLink: 'Mon porte-documents',
    logout: 'Me déconnecter',
    navigationLabel: 'Navigation',
    publicServiceIndividuals: 'Service Public Particuliers',
    publicServiceBusinesses: 'Service Public Entreprises',
    individuals: 'Particuliers',
    businesses: 'Entreprises',
    searchPlaceholder: 'Rechercher un service en ligne',
    documentSearchPlaceholder: 'Rechercher un document',
    popup: {
      mainText:
        'Pour accéder à l’espace {userTypePopup}, vous allez être déconnecté(e). <br/> Vous pourrez ensuite vous connecter à l’aide de vos identifiants {userTypePopup} (ou créer un compte).',
      logout: 'Vous déconnecter ?\n',
      wouldYouLikeToLogout: 'Souhaitez-vous vous déconnecter ?\n',
    },
    accessMonGuichet: 'Aller à MonGuichet.mc {userTypePopup}',
    partnersLabel: 'Consulter tous les sites liés',
    partnersCloseButtonActionLabel: 'Fermeture',
    partnersActionLabel: 'OuvertureSitesLiés',
    partnersLink: 'https://www.gouv.mc/Action-Gouvernementale/Sites-lies',
    infosLabel:
      'Toutes les informations et services utiles pour les usagers de la Principauté sur les sites officiels du Gouvernement Princier de Monaco',
    certifiedWebsiteLabel: 'Site officiel de la Principauté de Monaco',
    userAccountLabel: 'Compte : ',
    metanav: {
      governmentSiteHref: 'https://www.gouv.mc/',
      governmentSiteLabel:
        'Le site institutionnel du Gouvernement Princier de Monaco',
      monGuichetHref: 'https://monguichet.mc/',
      monGuichetLabel: 'Le portail pour réaliser vos démarches en ligne',
      myPublicServiceHref: 'https://monservicepublic.gouv.mc',
      myPublicServiceLabel:
        'Toutes les démarches et informations pour les particuliers',
      myCompanyServiceHref: 'https://monentreprise.gouv.mc',
      myCompanyServiceLabel:
        'Toutes les démarches et informations pour les entreprises',
    },
  },
  footer: {
    legal: 'Mentions légales',
    cookies: 'Cookies',
    contact: 'Contact',
    tou: 'CGU',
    copyright:
      'Tous droits réservés - Monaco {prodYear} - {currentYear} - {appVersion}',
    socialNetwork: 'Suivez-nous sur les réseaux sociaux',
    partnersLabel:
      'Retrouvez tous les sites partenaires liés au gouvernement sur ce lien',
    partnersLink: 'https://www.google.fr/',
    governmentLink: 'https://www.gouv.mc/',
    infosLabel:
      'Retrouvez toutes les informations et les services sur les sites du Gouvernement Princier de Monaco',
  },
  login: {
    login: 'Me connecter',
  },
  signup: {
    pageTitle: 'Créer votre compte',
    titleBusinesses: 'Créer votre compte Entreprises',
    backToAccountChoice: 'Retour',
    titleIndividuals: 'Créer votre compte Particuliers',
    address: 'Mon adresse',
    identifyWithMconnect: 'Je m’identifie avec MConnect',
    loginWithMconnect: 'M’identifier avec MConnect',
    chooseUsernames: 'Je choisis mes identifiants',
    usernamePlaceholder: 'Ex : johndoe06',
    alreadyHaveAnAccount: 'J’ai déjà un compte ?',
    haveVisa: 'Je suis titulaire d’une carte de Séjour',
    pleaseAcceptTerms:
      'Pour pouvoir utiliser ce service, merci de valider les <a href="{page}" target="blank" class="text-interaction">Conditions d’utilisation</a>.',
    iAcceptTerms:
      'J’ai lu et j’accepte les <a class="text-interaction" href="{termUrl}" target="blank">Conditions d’utilisation</a> ainsi que mes données personnelles soient traitées dans le cadre MonGuichet.mc.',
    checkYourMailbox: 'Je vérifie ma boîte mail',
    finalize:
      "Pour finaliser votre inscription, veuillez cliquer sur le <b>lien d'activation</b> qui vous a été envoyé sur votre adresse email.",
    finish: 'Terminer',
    socialReason: 'Raison sociale',
    NISNumber: 'Numéro NIS',
    CARNumber: 'Numéro CAR',
    RCINumber: 'Numéro RCI',
    socialReasonTooltip:
      "Nom de l'entreprise défini lors de sa création et qui figure au registre du commerce.",
    NISNumberTooltip:
      "Numéro d’Identification Statistique attribué par l'Institut Monégasque de la Statistique et des Études Économiques (IMSEE) lors de la création d'une activité.\n",
    NISNumberTooltip2:
      'Le NIS est composé de quatre chiffres, une lettre et cinq chiffres.',
    CARNumberTooltip:
      'Numéro employeur fourni par les Caisses Sociales de Monaco.',
    RCINumberTooltip:
      "Numéro d’immatriculation au Répertoire du Commerce et de l'Industrie.\n",
    RCINumberTooltip2:
      'Le RCI est composé du millésime sur deux chiffres, la lettre "P" ou "S", et cinq chiffres correspondant au numéro d\'inscription.',
    optionalInfos:
      'Ces informations sont facultatives, vous pourrez les compléter ultérieurement dans votre profil',
    socialReasonPlaceholder: 'Ex : MonEntreprise',
    NISNumberPlaceholder: 'Ex : 1234A56789',
    CARNumberPlaceholder: 'Ex : CAR',
    RCINumberPlaceholder: 'Ex : 09P12345',
  },
  home: {
    subtitle:
      'Retrouvez les démarches réalisables en ligne et suivez vos demandes',
    mainMessage:
      'Le portail des <span class="text-red_individual">services en ligne</span> du Gouvernement Princier et de la Mairie de Monaco',
    mainMessagePro:
      'Le portail des <span class="text-brand_blue_secondary">services en ligne</span> du Gouvernement Princier et de la Mairie de Monaco',
    followOngoingProcedures: 'Suivre mes demandes',
    followMyAppointments: 'Mes rendez-vous',
    findOnlineService: 'Trouver un service en ligne',
    fastAccesses: 'Accès rapides',
    myActivity: 'Mon activité',
    onlineServices: 'Services en ligne',
    myFavourites: 'Mes favoris',
    survey: {
      title: 'Donnez votre avis !',
      textIndividuals: `Aidez-nous à améliorer votre espace personnel MonGuichet.mc`,
      textProfessionals: `Aidez-nous à améliorer l’espace personnel MonGuichet.mc pour les professionnels.`,
      surveyButtonLabel: 'Répondre au sondage',
    },
    cards: {
      myProcedures: 'Mes démarches',
      myAppointments: 'Mes rendez-vous',
      documentsHolder: 'Mon porte-documents',
    },
    mconnectCard: {
      description:
        'Grâce à votre <span class="text-red_individual">identité numérique</span> Monégasque :',
      followAppointments: 'Suivez vos rendez-vous',
      secureConnection: 'Sécurisez votre connexion',
      simplifyMyProcedures: 'Simplifiez vos démarches en ligne',
      documentsHolder: 'Bénéficiez d’un porte-documents',
      askCivilStatusCertificates:
        'Demandez des actes d’Etat-Civil et de Nationalité',
      useMConnect: 'Utiliser',
    },
    mconnect:
      'Dorénavant, <strong class="font-semibold">vous devez utiliser MConnect</strong> pour accéder à votre compte Particuliers.',
    individuals: 'Particuliers',
    professional: 'Entreprises',
    revocation: 'Complétez vos informations d’identité numérique.',
    revocationActionLabel: 'Compléter mes informations',
  },
  actions: {
    modify: 'Modifier',
    cancel: 'Annuler',
    confirm: 'Valider',
    continue: 'Continuer',
    yes: 'Oui',
    no: 'Non',
    previous: 'Précédent',
    next: 'Suivant',
    select: 'Sélectionner',
    selectDocumentType: 'Sélectionner un type',
    search: 'Rechercher',
    addDocument: 'Ajouter un document',
    filter: 'Affiner',
    deleteFilters: 'Effacer les filtres',
    keepDocument: 'Conserver le document',
    keepDocuments: 'Conserver les documents',
    delete: 'Supprimer',
    deleteSelection: 'Supprimer | Supprimer les documents',
    mobileDeleteSelection:
      'Supprimer {count} document | Supprimer {count} documents',
    download: 'Télécharger',
    downloadSelection: 'Télécharger | Télécharger les documents',
    mobileDownloadSelection:
      'Télécharger {count} document | Télécharger {count} documents',
    selectAll: 'Tout sélectionner',
    unselectAll: 'Tout désélectionner',
    apply: 'Appliquer',
    terminate: 'Terminer',
    retry: 'Réessayer',
  },
  errors: {
    usernameContainsWhitespaces: 'L’identifiant ne doit pas contenir d’espaces',
    usernameDoesNotRespectPosixStandard:
      'Seuls les caractères alphanumériques, le point et les tirets peuvent être utilisés',
    addressMustBeValid: "L'adresse saisie est incorrecte",
    usernameFirstCharacterMustBeALetter:
      'Le premier caractère doit être une lettre',
    mandatoryField: 'Ce champ est obligatoire',
    invalidEmail:
      'Adresse e-mail invalide ou vous ne disposez d’aucun compte activé',
    incorrectEmail: "L'adresse email est incorrecte",
    emailsMustMatch:
      "L'adresse e-mail et sa confirmation doivent être identiques.",
    incorrectPassword: 'Le mot de passe est incorrect',
    passwordsMustMatch:
      'Le mot de passe et sa confirmation doivent être identiques.',
    mustNotContainANumber: 'Ce champ ne doit pas contenir de chiffre',
    minLength: 'Ce champ doit contenir au minimum {limit} caractères',
    minPasswordLength: 'Le mot de passe doit faire {limit} caractères minimum',
    passwordShouldContainLowercaseLetter:
      'Le mot de passe doit contenir au moins une minuscule.',
    passwordShouldContainUppercaseLetter:
      'Le mot de passe doit contenir au moins une majuscule.',
    passwordShouldContainANumber:
      'Le mot de passe doit contenir au moins un chiffre.',
    passwordShouldContainASpecialCharacter:
      'Le mot de passe doit contenir au moins un symbole',
    maxLength: 'Ce champ doit contenir au maximum {limit} caractères',
    password: {
      mustContain: 'Le mot de passe doit contenir : ',
      length: '9 caractères minimum',
      uppercase: 'au moins 1 majuscule',
      lowercase: 'au moins 1 minuscule',
      number: 'au moins 1 chiffre',
      special:
        "au moins 1 caractère spécial parmi () ? ! _’; : {'@'} # & * + =",
    },
    usernameAlreadyTaken:
      'Identifiant existant. Si vous avez déjà créé un compte sur le site de Service Public, identifiez-vous avec votre identifiant habituel.',
    expectedDateFormat: 'Le format attendu est JJ/MM/YYYY',
    dateMustBeBeforeToday: 'La date doit être antérieur à la date du jour',
    somethingWentWrong: 'Quelque chose ne va pas',
    emailSameAsOld: 'Cette adresse e-mail est la même que l’ancienne.',
    wrongCredential:
      'Identifiant et/ou Mot de passe incorrect. Rappel : Si vous avez déjà utilisé MConnect pour accéder à votre compte Particuliers, identifiez-vous avec MConnect uniquement.',
    numericIdIsOnlyForIndividuals:
      'L’identité numérique est disponible uniquement pour les particuliers, nous ne sommes pas en mesure de lier votre identité numérique à un compte Entreprises.',
    captchaError: "Le captcha n'a pas été verifié",
    invalidFileSize:
      'La taille du fichier sélectionné excède la limite de {limit} Mo',
    invalidFileExtension: 'L’extension du fichier sélectionné est invalide',
  },
  forgotIdentifier: {
    title: 'Identifiant oublié ?',
    hint: 'Pour retrouver votre identifiant, veuillez saisir l’adresse e-mail utilisée lors de la création de votre compte.',
    confirmationTitle: 'Vérifiez votre boîte mail',
    sent: 'Votre identifiant vous a été envoyé par e-mail.',
  },

  profile: {
    profile: 'Votre profil',
    account: 'Mon compte',
    usernames: 'Mes identifiants',
    username: 'Identifiant',
    password: 'Mot de passe',
    confirmPassword: 'Confirmation mot de passe',
    aboutMe: 'À propos de moi',
    homeAddress: 'Mon domicile',
    companyAddress: 'Adresse de mon entreprise',
    myPersonalInfo: 'Mes informations personnelles',
    myInfo: 'Mes informations',
    companyInfo: 'Informations entreprise',
    email: 'Adresse e-mail',
    modifyEmail: "Modifier l'adresse e-mail",
    modifyPassword: 'Modifier le mot de passe',
    identifier: 'Identifiant',
    technicalIdentifier: 'Compte',
    emailConfirmation: 'Confirmation adresse e-mail',
    contactEmail: 'Adresse e-mail de contact ',
    login: 'Me connecter',
    identity: 'Mon identité',
    contactInfos: 'Mes informations de contact',
    address: 'Mon adresse',
    company: 'Mon entreprise',
    personalInfos: {
      surname: "Nom d'usage",
      MconnectSurname: 'Nom de naissance',
      firstName: 'Prénom',
      secondName: 'Second prénom',
      thirdName: 'Troisième prénom',
      nationality: 'Nationalité',
      civility: {
        civility: 'Civilité',
        madam: 'Madame',
        sir: 'Monsieur',
        modify: 'Modifier ma civilité',
      },
      residencePermitHolder: 'Titulaire d’une carte de séjour',
    },
    aboutMyCompany: {
      aboutMyCompany: 'Informations de mon entreprise',
      socialReason: 'Raison sociale',
      nisNumber: 'Numéro NIS',
      carNumber: 'Numéro CAR',
      rciNumber: 'Numéro RCI',
    },
    addressInfos: {
      streetAddress: 'Adresse de résidence',
      companyStreetAddress: "Adresse de l'entreprise",
      additionalAddress: "Complément d'adresse",
      secondAdditionalAddress: "Second complément d'adresse",
      additionnalAddressPlaceholder:
        'Société, lieu-dit, bât., appart., étage, BP,..',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      additionalAddressLabel: 'Complément d’adresse',
    },
    changeEmail: {
      title: 'Modifier votre adresse e-mail',
      enterPassword: ' Veuillez d’abord saisir votre mot de passe',
      passwordLabel: ' Mot de passe',
      passwordPlaceholder: 'Mot de passe',
      enterEmail: 'Saisissez votre nouvelle adresse e-mail',
      emailLabel: 'Nouvelle adresse e-mail',
      confirmEmailLabel: 'Confirmation nouvelle adresse e-mail',
      success: 'Votre email a bien été mis à jour',
      confirmationSentence: 'Un e-mail de confirmation vous a été transmis.',
      backToProfile: 'Retourner à mon profil',
    },
    confirmEmail: {
      title: 'Vérifiez votre boîte mail',
      text: "Pour activer votre nouvelle adresse e-mail, </br> veuillez cliquer sur le lien d'activation qui vous a été envoyé sur l’adresse e-mail indiquée.",
    },
    unsubscribe: 'Me désinscrire',
    unsubscribePopup: {
      body: 'Si vous souhaitez vous désinscrire, vous pouvez adresser un message en cliquant ici : <a class="text-interaction cursor-pointer" href="https://contacts.gouv.mc/contactez-monguichet" target="blank">https://contacts.gouv.mc/contactez-monguichet</a> en indiquant votre identifiant et votre adresse email de contact.',
      title: 'Vous désinscrire?',
      footer:
        'En demandant la désinscription, vous ne pourrez plus vous connecter avec ce compte et vous serez désinscrit de tous les téléservices auxquels vous étiez inscrits.',
    },
    digitalIdentity: {
      verifiedBy: 'Verifié par',
      migrateToTitle: "Passez à l'identité numérique",
      migrateToLabel:
        'Que vous soyez monegasque ou résident, passez à l’identité numérique pour bénéficier de services inédits et réduire vos nombres d’identifiants et de mots de passe !',
      useMConnect: 'Utiliser MConnect pour me connecter à MonGuichet',
      getMConnect: 'Obtenir l’identité numérique',
      title: 'Mon Identité Numérique',
      name: 'Nom de naissance',
      nameUse: "Nom d'usage",
      firstName: 'Prénom(s)',
      nationality: 'Nationalité',
      youAre: 'Vous êtes',
      birth: 'Date de naissance',
      cityOfBirth: 'Ville de naissance',
      countryOfBirth: 'Pays de naissance',
      registrationAuthority: "Autorité d'enregistrement",
      resident: 'Résident',
      nonResident: 'Non Résident',
      monagasque: 'Monégasque',
      policeDepartment: 'Direction de la sûreté publique',
      cityHall: 'Mairie de Monaco',
      digitalServicesDepartment: 'Direction des Services Numériques',
      information:
        'Ces informations sont erronées ? Contactez le support de l’identité numérique en cliquant ici !',
    },
    digitalIdentityRegister: {
      title: 'Finaliser la création de votre compte',
      subtitle:
        'Veuillez renseigner votre adresse pour finaliser la création de votre compte.',
      addressLabel: 'Adresse',
      addressPlaceholder: 'Ex : 4, allée des roses',
      additionnalAddressPlaceholder: 'Complément d’adresse',
      zipCodeLabel: 'Code postal',
      zipCodePlaceholder: 'Ex : 98000',
      cityLabel: 'Ville',
      cityPlaceholder: 'Ex : Monaco',
      countryLabel: 'Pays',
      select: 'Sélectionner',
      button: 'Créer mon compte',
    },
    fromProcedure: {
      backToProcedure: 'Reprendre ma démarche',
      warning:
        'Vous avez entamé une démarche en ligne. Vous pouvez modifier vos informations ci-dessous, puis reprendre votre démarche.',
    },
    useMconnectBox: {
      title: 'Passez à l’identité numérique',
      content:
        '<span class="font-semibold">Monégasques ou résidents</span>, bénéficiez de services inédits et réduisez vos nombres d’identifiants et de mots de passe!',
      button: 'Utiliser MConnect',
      linkLabel: 'Obtenir l’identité numérique',
      link: 'https://mconnect.gouv.mc/decouvrir',
    },
    useMconnectPage: {
      route: '/utiliser-mconnect',
      title: 'Utiliser MConnect pour me connecter à MonGuichet',
      alertMessage:
        'Vous allez <strong>associer votre compte MonGuichet.mc</strong> à votre Identité Numérique.<br/> Une fois votre compte associé, vous devrez utiliser <strong>MConnect pour vous connecter</strong> aux services en ligne.',
      youMust: 'Pour utiliser MConnect, vous devez :',
      step1: '1. Avoir activé votre identité numérique Monégasque',
      info1: 'Comment obtenir et activer l’identité numérique',
      step2:
        '2. Avoir installé l’application MConnect Mobile sur votre smartphone',
      info2: 'Comment installer MConnect Mobile',
      connectBtn: 'Me connecter avec MConnect',
      installLink: 'https://mconnect.gouv.mc/utiliser/installer-sur-mobile',
    },
    revocation: {
      notCompleted: {
        title: 'Informations à compléter',
        announcement:
          'Il manque des informations permettant de renforcer la sécurité de votre compte',
        subtitle:
          '<span class="font-semibold">Complétez vos informations d’identité numérique</span> pour :',
        explanation1:
          'Être notifié du renouvellement de l’usage de votre identité numérique',
        explanation2:
          'En cas de perte ou de vol de votre carte, bloquer votre identité numérique',
        actionLabel: 'Compléter mes informations',
        knowMore: 'En savoir plus sur mconnect.gouv.mc',
      },
      completed: {
        title: 'Informations complétées',
        announcement: 'Vos informations de compte sont à jour',
        subtitle: 'Dès maintenant, vous pouvez :',
        explanation1:
          'Mettre à jour vos données de contact en cas de changement',
        explanation2:
          'En cas de perte ou de vol de votre carte, bloquer votre identité numérique',
        actionLabel: 'Accéder au service',
        knowMore: 'En savoir plus sur mconnect.gouv.mc',
      },
    },
  },
  services: {
    title: 'Mes démarches',
    followMyServicesTitle: 'Voir toutes mes démarches',
    subtitle: 'Retrouvez ici vos démarches réalisées',
    updated: 'Mise à jour',
    accessTo: 'Accéder',
    update: 'Compléter',
    categories: 'Filtres | Filtres :',
    request: 'demande | demandes',
    beingProcessed: 'En cours',
    beingProcessedFullLabel: 'En cours de traitement',
    waitingForUserRequest: 'En attente',
    waitingForUserRequestFullLabel: 'En attente d’action',
    treated: 'Traitées',
    refused: 'Refusé',
    completedRequest: 'Terminé',
    delete: 'Effacer',
    showResults: 'Afficher les résultats',
    informations: {
      title: 'Vous n’avez pas trouvé le suivi de votre demande en ligne ?',
      paperApplication: 'Vous avez fait une demande papier :',
      paperApplicationExplanation:
        'Nous ne proposons pas encore le suivi des démarches qui n’ont pas été réalisées en ligne.',
      onlineApplication: 'Vous avez fait une demande en ligne :',
      onlineApplicationExplanation:
        'Nous n’avons pas encore relié toutes les démarches à MonGuichet.mc.',
      onlineApplicationTracking:
        'Retrouvez le suivi directement dans le Téléservice concerné.',
      missingOnlineApplication: 'Le suivi de votre demande a disparu ?',
      missingOnlineApplicationExplanation:
        'Votre demande pourrait avoir été supprimée si elle avait atteint sa durée de conservation maximale.',
    },
    announcementLabel:
      'Vous avez une demande en attente d’action de votre part. Veuillez la compléter afin que l’Administration puisse la traiter. | Vous avez des demandes en attente d’action de votre part. Veuillez les compléter afin que l’Administration puisse les traiter.',
    noServices: {
      title: 'Aucune démarche en cours',
      description:
        'Seules les démarches que vous avez effectuées <span class="font-semibold">en ligne</span> peuvent apparaitre <span class="font-semibold">ici</span>.',
      action: 'Trouver un service en ligne',
    },
  },
  onlineProcedures: {
    title: 'Services en ligne',
    subtitle: 'Retrouvez ici les démarches que vous pouvez réaliser en ligne.',
    searchText: 'Rechercher une démarche ou un service en ligne ',
    categories: 'Filtres :',
    results: 'résultat  | résultats ',
    tabs: {
      explore: 'Explorer',
      myFavourites: 'Mes favoris',
    },
    noFavourites: {
      title: 'Aucun favori',
      add: 'Pour ajouter un service en favori :',
      item1: "Allez dans l'onglet Explorer",
      item2: 'Trouvez le service qui vous intéresse',
      item3: 'Cliquez sur les trois petits points',
      item4: 'Cliquez sur "Ajouter aux favoris"',
    },
    card: {
      consult: 'Comprendre la démarche',
      access: 'Accéder au service',
      frenchAvailability: '',
      englandCode: 'EN',
      franceCode: 'FR',
      mconnectOnly: 'Service accessible uniquement via ',
      mconnectOnlyFull: 'Service accessible uniquement via MConnect',
      cityHallService: 'Démarche de la Mairie de Monaco',
      addToFavourites: 'Ajouter aux favoris',
      removeFromFavourites: 'Supprimer des favoris',
    },
    navigation: {
      title: 'Vous n’avez pas trouvé ce que vous cherchiez ?',
      servicePublicIndividuals: 'MonServicePublic',
      servicePublicCompany: 'MonEntreprise',
      notOnlineYet:
        'Votre démarche n’est peut-être pas encore réalisable en ligne. <br/>Trouvez la procédure à suivre sur le site de {siteName}.gouv.mc.',
      publicServiceButtonLabel: 'Accéder à {siteName}',
      scrollTop: 'Retourner en haut de page',
    },
    noResults: {
      checkSpelling:
        "Vérifiez l'orthographe des mots-clés et essayez de changer certains mots-clés (par ex. 'voiture' au lieu de 'voitures'). Vous pouvez également explorer une thématique (ex. emploi, etc...) dans notre catalogue des démarches.",
      notAvailable:
        'Votre démarche n’est peut-être pas encore réalisable en ligne. Trouvez la procédure à suivre sur le site de {siteName}.gouv.mc.',
    },
    availableServicesButtonLabel: 'Accéder au catalogue des démarches',
    availableLanguages: '',
    deleteFavourite: {
      title: 'Supprimer un favori',
      warning: 'Êtes-vous sûr de vouloir supprimer ce favori ?',
      keep: 'Annuler',
      delete: 'Supprimer',
    },
    mconnectOnly: {
      title: 'Service accessible via MConnect',
      warning:
        'Pour accéder à ce service, vous devez utiliser la connexion via MConnect.',
      description1:
        'Actuellement, vous êtes connecté à MonGuichet.mc avec votre identifiant et votre mot de passe.',
      description2:
        'Si vous avez déjà une identité numérique Monégasque, vous pouvez dès maintenant utiliser MConnect pour vous connecter à MonGuichet et accéder à ce service.',
      description3:
        'Si vous n’avez pas d’identité numérique Monégasque, cliquez sur "obtenir une identité numérique".',
      useMyNumericIdentity: 'Utiliser mon identité numérique',
      getANumericIdentity: 'Obtenir une identité numérique',
      cancel: 'Annuler',
    },
  },

  appointments: {
    title: 'Mes rendez-vous',
    subtitle: 'Retrouvez ici vos rendez-vous administratifs pris en ligne',
    next: 'Prochain RDV',
    bookAnAppointment: 'Prendre rendez-vous',
    subheader: {
      incoming: 'À venir',
      passed: 'Passés',
    },
    followingCard: {
      incoming: 'Rendez-vous à venir',
      passed: 'Rendez-vous passés',
      seeAll: 'Voir tous mes rendez-vous',
    },
    description: {
      incoming: 'rendez-vous à venir :',
      noneIncoming: 'Aucun rendez-vous à venir',
      passed: 'rendez-vous passé : | rendez-vous passés :',
      nonePassed: 'Aucun rendez-vous passé',
    },
    noAppointments: {
      incomingTitle: 'Aucun rendez-vous à venir',
      passedTitle: 'Aucun rendez-vous passé',
      description:
        'Seuls les rendez-vous que vous avez pris <span class="font-semibold">en ligne</span> peuvent apparaître <span class="font-semibold">ici</span>',
    },
    notFound: {
      title: 'Vous n’avez pas trouvé le suivi de votre rendez-vous ?',
      explanation1:
        "Actuellement, vos rendez-vous pris en ligne avec la Section des Résidents de la DSP, la Direction du Travail (DT), le Service des Titres de Circulation (STC) et la Mairie de Monaco apparaissent ici. Nous n'avons pas encore relié tous les services de rendez-vous à MonGuichet.mc.",
      explanation2:
        'Retrouvez le suivi directement dans le courriel ou SMS de confirmation de votre rendez-vous.',
      explanation3:
        'Les rendez-vous sont affichés dans votre espace <span class="font-semibold">pendant 1 mois</span> après la date du rendez-vous. Passé ce délai, ils sont automatiquement supprimés.',
    },
    dropdown: {
      headerLabel: 'Prendre rendez-vous',
      bodyContent:
        'Vous pouvez prendre directement rendez-vous en ligne avec certains services de l’Administration :',
    },
    modifyAppointment: 'Modifier',
    vehiclePlateNumber: 'Immatriculation :',
    addAppointment: 'Ajouter à mon agenda',
    reason: 'Motif :',
    with: 'Avec :',
    place: 'Lieu :',
    statuses: {
      pending: 'En attente d’action',
      scheduled: 'Planifié',
      cancelled: 'Annulé',
      declined: 'Refusé',
      noShow: 'Absent',
      completed: 'Réalisé',
      inProgress: 'En cours de traitement',
    },
  },

  docHolder: {
    noDocument: {
      title: 'Aucun document',
      description:
        'Vous pouvez ajouter des documents à partir de votre ordinateur ou votre téléphone mobile.',
      explanationTitle: 'Le porte-documents vous permet de :',
      explanationFirstStep:
        '<b>Ajouter</b> vos documents souvent utilisés dans les démarches (<b>justificatif de domicile, justificatif d’identité...</b>) depuis MonGuichet ou un téléservice',
      explanationSecondStep:
        '<b>Centraliser</b> vos documents dans votre espace personnel MonGuichet',
      explanationThirdStep:
        '<b>Réutiliser</b> vos documents d’une démarche à l’autre, <b>depuis n’importe quel appareil</b>',
    },
    documentsDisplay: {
      count: 'document sélectionné | documents sélectionnés',
      description:
        'Vous êtes responsable de la durée de conservation de vos documents. Ces derniers seront conservés tant que votre compte MonGuichet est actif et que votre consentement lié à la fonctionnalité porte-documents est valide. Vous pouvez utiliser les documents déposés ici dans vos démarches en ligne.',
      add: 'Ajouté le :',
      sizeLabel: 'Taille :',
      sizeUnit: 'Mo',
      format: 'Format :',
      type: 'Type de justificatif :',
      endOfValidity: 'Fin de validité :',
      file: "Fichier d'origine :",
      docInfos: 'Informations du document',
      select: 'Sélectionnez un document pour en savoir plus',
      filters: {
        filterBy: 'Filtrer par',
        byJustificativeTypeLabel: 'Filtrer par type de justificatif',
        allType: 'Tous les types de justificatif',
        byAdditionDate: "Trier par date d'ajout",
        antechronologicalOrder: 'Du plus récent au plus ancien',
        chronologicalOrder: 'Du plus ancien au plus récent',
      },
      shouldDisplayMultipleSelection:
        'Activer la sélection multiple de documents',
      activated: 'Activée',
      deactivated: 'Désactivée',
      noResults: 'Aucun résultat',
    },
    addDocument: {
      title: 'Ajouter un document',
      fileLabel: 'Fichier',
      firstCondition: 'Le fichier ne doit pas excéder {size} Mo.',
      secondCondition: 'Formats acceptés : {types}',
      documentName: 'Nom du document',
      documentNamePlaceholder: 'ex : mon_fichier_2023',
      documentType: 'Type de justificatif',
      endOfValidity: 'Fin de validité',
      endOfValidityDateFormat: 'JJ/MM/AAAA',
      loadingLabel: 'Chargement de votre document ...',
      successLabel: 'Le chargement a abouti avec succés !',
      failureLabel: 'Le chargement a échoué !',
    },
    termsOfUse: {
      title: 'Politique de protection des données',
      subtitle: 'Utilisation de votre porte-documents MonGuichet.mc :',
      content1:
        'Sous réserve de votre consentement (case à cocher ci-dessous), vous pouvez déposer et stocker des documents ainsi qu’autoriser leur récupération par un téléservice pour vos démarches.',
      content2:
        'Ces documents peuvent contenir des données personnelles. Ils sont enregistrés et conservés dans votre Porte-documents sur votre compte MonGuichet.mc par la Direction des Services Numériques de l’Etat de Monaco, en tant que responsable du traitement, afin de faciliter votre utilisation des téléservices.',
      content3:
        'Vous disposez des droits suivants : droit d’accès aux données, droit de retirer votre consentement à tout moment, droit de demander que soient rectifiées ou supprimées les données inexactes, incomplètes ou périmées.',
      content4:
        'Toutes les informations relatives aux traitements de vos données personnelles sont disponibles dans les <a href="{termsOfUseLink}" target="_blank" class="text-interaction underline">CGU</a>.',
      legal:
        'J’accepte que mes documents soient traités par le service Porte-documents<span class="text-red">*</span>',
      actionConsent: "J'accepte",
      actionDeny: 'Je refuse',
    },
    modifyDocument: {
      title: 'Modifier un document',
    },
    deleteDocument: {
      title: 'Supprimer un document',
      warning: 'Êtes-vous sûr de vouloir supprimer ce document ?',
      description: 'Il n’apparaitra plus dans votre porte-documents.',
      loadingLabel: 'Suppression de votre document ...',
      successLabel: 'La suppression a abouti avec succés !',
      failureLabel: 'La suppression a échoué !',
    },
    massDeleteDocument: {
      title: 'Supprimer les documents sélectionnés',
      warning: 'Êtes-vous sûr de vouloir supprimer ces documents ?',
      description: 'Ils n’apparaitront plus dans votre porte-documents.',
      loadingLabel: 'Suppression de vos documents ...',
    },
    documentDetails: {
      title: 'Détails du document',
    },
    deleteDocHolder: {
      action: 'Supprimer mon porte-documents',
      confirmation:
        'Êtes-vous sûr de vouloir supprimer votre espace porte-documents ?',
      warning:
        'Votre consentement au porte-documents ainsi que vos documents déposés seront automatiquement supprimés',
      keep: 'Conserver mon porte-documents',
      delete: 'Supprimer',
      loadingLabel: 'Suppression de votre porte-documents ...',
      successLabel:
        'La suppression de votre porte-documents a abouti avec succés !',
      failureLabel: 'La suppression de votre porte-documents a échoué !',
    },
    announcement: {
      warningDeactivation:
        'Votre consentement à l’usage du porte-documents arrivera à expiration le : {date}.<br/>Si vous souhaitez conserver l’usage de votre porte-documents, merci de renouveler votre consentement.',
      warningDeletion:
        'Sans votre consentement, vos documents seront automatiquement supprimés le {date}.<br/>Si vous souhaitez conserver l’usage de votre porte-documents, merci de renouveler votre consentement.',
      action: 'Renouveller mon consentement',
    },
  },
  head: {
    businesses: 'Entreprises',
    individuals: 'Particuliers',
    connecting: 'Connexion',
    register: 'Création de compte',
    forgotIdentifier: "Oubli d'identifiant",
    error: 'Erreur',
  },
  apiGichuni: {
    title: 'Specification API',
  },
  porteDoc: {
    title: 'Porte doc test',
  },
  contact: {
    title: 'Contact',
    link: 'https://contacts.gouv.mc/contactez-monguichet',
  },
  register: {
    emailAlreadyExistsText:
      'L’adresse email de contact {email} est déjà associée à un compte existant',
    explanationFirst: `Nous avons détecté qu'il existe déjà un ou plusieurs comptes associés à l'adresse e-mail de contact <b>{email}</b>.`,
    explanationSecond:
      "Pour retrouver l'historique de vos démarches, connectez-vous avec votre compte existant.",
    connect: 'Me connecter',
    findIdentifier: 'Retrouver mon identifiant',
    ignore: 'Ignorer, continuer la création de compte',
  },
  locales: {
    en: {
      text: 'EN',
      option: 'English',
      value: 'en',
    },
    fr: {
      text: 'FR',
      option: 'Français',
      value: 'fr',
    },
  },
}
